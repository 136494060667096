import { Box, Button } from "@material-ui/core";
import { useNavigate } from "react-router";

import "./index.scss";
import { Slide } from "react-awesome-reveal";

const ToolsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <h1 className="tools-page-header">Tools</h1> */}
      <Box
        style={{ paddingBottom: "5%" }}
        className={
          window.location.href.includes("tools")
            ? "tools-page-container-landing"
            : "tools-page-container"
        }
      >
        <Slide duration={2000}>
          <Box className="tools-types-container">
            <Box className="tools-types-risk-img">
              <img src="icon-zero-commission.svg" alt="zero commission icon" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">
                Near-Zero Commission Trading
              </h1>
              <span className="tools-types-spans">
                Unlock global trading opportunities with Dachvest where you
                <br></br>
                can explore the markets with no added commission fees.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                Zero commission applies exclusively to Dachvest clients and
                <br></br>
                does not cover short or leveraged stock trades. While broker
                <br></br>
                fees are waived on trade execution, other charges may still
                <br></br>
                apply.
              </span>
              <br></br>
              <br></br>
              <div className="button-wrap">
                <Button
                  variant="outlined"
                  color="inherit"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div>
            </Box>
          </Box>
        </Slide>
        <Slide duration={2000} direction="right">
          <Box className="tools-types-container-2">
            <Box style={{ marginTop: "30px" }} className="tools-types-risk-img">
              <img src="icon-risk-management.svg" alt="risk management icon" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">Effective Risk Management</h1>
              <span className="tools-types-spans">
                We've developed a powerful combination of technical and<br></br>
                fundamental analysis, customized account types, rapid trade
                <br></br>
                execution, and specialized software solutions.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                With Stop Loss and Take Profit tools, you can set predefined
                <br></br>
                levels to automatically manage your gains and losses.
              </span>
              <br></br>
              <br></br>
              <span className="tools-types-spans">
                Risk is a crucial aspect of investing. Utilize our Risk{" "}
                <br></br>
                Management Tools alongside expert guidance from your<br></br>{" "}
                financial analyst to optimize your trading strategy.
              </span>
              <br></br>
              <br></br>

              <div className="button-wrap">
                <Button
                  color="inherit"
                  variant="outlined"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div>
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default ToolsPage;
