import { Box, Container, Grid, Link } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";
import PaymentFooter from "./components/PaymentFooter";

import "./index.scss";
function Footer() {
  const navigate = useNavigate();
  const mobileWidth = window.innerWidth;
  //asd
  return (
    <div className="footer-container">
      <Box className="footer-inner-container">
        <Container maxWidth="xl">
          <Grid container>
            {/* <LegalFooter /> */}
            <PaymentFooter />
            <Grid item className="grid-inner-container">
              <p>
                <strong className="footer-strong">RISK WARNING</strong>
                <br></br>
                CFDs are leveraged instruments and carry a high level of risk,
                potentially leading to the loss of your entire capital. These
                products may not be suitable for all investors. Before trading,
                ensure you fully understand the risks involved, consider your
                level of experience, and seek independent financial advice if
                needed.<br></br> We do not accept liability for any loss or
                damage resulting from CFD transactions, including direct,
                indirect, special, or consequential losses.
              </p>
            </Grid>
          </Grid>
          <Box className="footer-container-box">
            <Box onClick={() => navigate("/privacy")}>
              <Link className="footer-container-link">Privacy Policy</Link>
            </Box>
            <Box onClick={() => navigate("/terms")}>
              <Link className="footer-container-link">Terms & Conditions</Link>
            </Box>
            <Box onClick={() => navigate("/aml")}>
              <Link className="footer-container-link">AML Policy</Link>
            </Box>
            <Box onClick={() => navigate("/deposit-policy")}>
              <Link className="footer-container-link">Deposit Policy</Link>
            </Box>
            <Box onClick={() => navigate("/refund-policy")}>
              <Link className="footer-container-link">Refund Policy</Link>
            </Box>
            <Box onClick={() => navigate("/security-policy")}>
              <Link className="footer-container-link">Security Policy</Link>
            </Box>
            <Box onClick={() => navigate("/risk-disclaimer")}>
              <Link className="footer-container-link">Risk Disclaimer</Link>
            </Box>
            <Box onClick={() => navigate("/kyc")}>
              <Link className="footer-container-link">KYC Policy</Link>
            </Box>
            <Box onClick={() => navigate("/security")}>
              <Link className="footer-container-link">Security</Link>
            </Box>
            <Box onClick={() => navigate("/mobile-privacy")}>
              <Link className="footer-container-link">Mobile Privacy</Link>
            </Box>
          </Box>
          <Box className="location-box-container">
            <span>
              © 2023 Dachvest | Brussels Offices Center - Business Center
              Brussels Bte. 8, Av. Frans van Kalken 9, 1070 Brussel, Belgien
            </span>
          </Box>
        </Container>
      </Box>
    </div>
  );
}

export default Footer;
